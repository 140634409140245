import React, { useState, useEffect } from 'react';
import './cookiebanner.css'

const CookieBanner = () => {
  const [cookieAccepted, setCookieAccepted] = useState(false);

  useEffect(() => {
    const accepted = localStorage.getItem('cookieAccepted');
    setCookieAccepted(accepted === 'true');
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieAccepted', 'true');
    setCookieAccepted(true);
  };

  if (cookieAccepted) return null;

  return (
    <div className="cookie-banner">
      <p>Usamos cookies para mejorar tu experiencia. Al continuar, aceptas nuestro uso de cookies.</p>
      <button onClick={handleAccept}>Aceptar</button>
    </div>
  );
};

export default CookieBanner;
