import React, { useState } from 'react';
import Navbar from './components/Navbar';
import Inicio from './components/Inicio';
import Servicios from './components/Servicios';
import Contacto from './components/Contacto';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy';
import './App.css';

function App() {
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  const openPrivacyPolicy = () => setShowPrivacyPolicy(true);
  const closePrivacyPolicy = () => setShowPrivacyPolicy(false);

  return (
    <div className="App">
      <Navbar />
      <Inicio />
      <Servicios />
      <Contacto />
      <Footer openPrivacyPolicy={openPrivacyPolicy} />
      {showPrivacyPolicy && (
        <div className="modal">
          <div className="modal-content">
            <PrivacyPolicy />
            <button onClick={closePrivacyPolicy}>Aceptar</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
