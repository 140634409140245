import React from 'react';
import './Navbar.css';
import logo from '../assets/logo_cinfex_sin_fondo.png';

const Navbar = () => (
  <nav className="navbar">
    <div className="logo-container">
      <img src={logo} alt="Logo CINFEX" className="logo" />
      <div className="company-name">
        <h1>CINFEX</h1>
        <p>Consultoría Informática</p>
      </div>
    </div>
    <ul>
      <li><a href="#inicio">Inicio</a></li>
      <li><a href="#servicios">Servicios</a></li>
      <li><a href="#contacto">Contacto</a></li>
    </ul>
  </nav>
);

export default Navbar;
