import React, { createContext } from 'react';

const NavigationContext = createContext(null);

export const NavigationProvider = ({ children }) => {
  const basename = '/mi-ruta-base'; // Ajusta según tus necesidades
  return (
    <NavigationContext.Provider value={{ basename }}>
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationContext;
