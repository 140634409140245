import React from 'react';
import './Footer.css';

const Footer = ({ openPrivacyPolicy }) => (
  <footer className="footer">
    <p>&copy; {new Date().getFullYear()} CINFEX Consultoría Informática. Todos los derechos reservados.</p>
    <button onClick={openPrivacyPolicy}>Política de Privacidad</button>
    <div class="social-media-links">
    <a href="https://facebook.com/cinfex" target="_blank" rel="noopener noreferrer">
        <i class="fab fa-facebook"></i> Facebook
    </a>
    <a href="https://x.com/Cinfexconsultor" target="_blank" rel="noopener noreferrer">
        <i class="fab fa-twitter"></i> Twitter
    </a>
    <a href="https://www.linkedin.com/company/cinfex/" target="_blank" rel="noopener noreferrer">
        <i class="fab fa-linkedin"></i> LinkedIn
    </a>
    <a href="https://instagram.com/cinfex" target="_blank" rel="noopener noreferrer">
        <i class="fab fa-instagram"></i> Instagram
    </a>
</div>
  </footer>
);

export default Footer;
