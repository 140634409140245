// src/components/Servicios.js
import React from 'react';
import Servicio from './Servicio';
import './Servicios.css';

import consultoriaIcon from '../assets/consulit.webp';
import desarrolloSoftwareIcon from '../assets/desarrollo.webp';
import soporteTecnicoIcon from '../assets/soporte.webp';
import seguridadInformaticaIcon from '../assets/ciberseguridad.webp';
import communityManagerIcon from '../assets/community.webp'

const serviciosData = [
  {
    icono: consultoriaIcon,
    titulo: "Consultoría en TI",
    descripcion: "Evaluamos el estado actual de tu infraestructura tecnológica y te brindamos recomendaciones personalizadas. Nuestro equipo de expertos trabaja contigo para identificar áreas de mejora, reducir costos, y optimizar el rendimiento. Nos aseguramos de que las soluciones estén alineadas con los objetivos estratégicos de tu empresa.",
  },
  {
    icono: desarrolloSoftwareIcon,
    titulo: "Desarrollo de Software",
    descripcion: "Diseñamos y desarrollamos software a medida que responde a las necesidades específicas de tu negocio. Desde aplicaciones empresariales hasta herramientas internas, nuestro enfoque está en la calidad y en la satisfacción del cliente. Utilizamos metodologías ágiles para asegurar una entrega rápida y efectiva.",
  },
  {
    icono: soporteTecnicoIcon,
    titulo: "Soporte Técnico",
    descripcion: "Ofrecemos soporte técnico continuo, para que tus operaciones nunca se detengan. Nuestro equipo de soporte está disponible para resolver problemas técnicos, realizar mantenimientos preventivos y garantizar que todos los sistemas funcionen de manera óptima.",
  },
  {
    icono: seguridadInformaticaIcon,
    titulo: "Seguridad Informática",
    descripcion: "Implementamos soluciones de seguridad avanzadas para proteger tu empresa de amenazas cibernéticas. Desde la protección de redes hasta la gestión de accesos, nos aseguramos de que tus datos estén seguros y cumplan con las normativas de seguridad.",
  },

  {
    icono: communityManagerIcon,
    titulo: "Community Manager",
    descripcion: "Gestionamos la presencia de tu marca en redes sociales y otros canales digitales, creando contenido atractivo y desarrollando estrategias de comunicación efectivas. Nuestro equipo se encarga de interactuar con tus seguidores, gestionar tu reputación online y analizar los resultados para optimizar el crecimiento y el compromiso con tu audiencia.",
  }
 
];

const Servicios = () => (
  <section id="servicios" className="servicios section-offset">
    <h2>Nuestros Servicios</h2>
    <div className="servicios-lista">
      {serviciosData.map((servicio, index) => (
        <Servicio
          key={index}
          icono={servicio.icono}
          titulo={servicio.titulo}
          descripcion={servicio.descripcion}
        />
      ))}
    </div>
  </section>
);

export default Servicios;
