import React from 'react';


const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Política de Privacidad</h1>
      <p><strong>Última actualización:</strong> 01/11/2024</p>
      
      <section>
        <h2>1. Recolección de Información</h2>
        <p>Para poder responder a las consultas enviadas a través del formulario de contacto en nuestro sitio web, solicitamos a los usuarios información básica, como:</p>
        <ul>
          <li>Nombre</li>
          <li>Correo electrónico</li>
          <li>Nombre de la empresa (opcional)</li>
          <li>Número de contacto (opcional)</li>
          <li>Mensaje o consulta</li>
        </ul>
      </section>

      <section>
        <h2>2. Uso de la Información</h2>
        <p>La información proporcionada por el usuario en el formulario de contacto es utilizada únicamente para responder a la consulta enviada. No almacenamos esta información en nuestras bases de datos ni compartimos estos datos con terceros. Una vez que el correo es enviado, los datos se eliminan automáticamente de nuestro servidor.</p>
      </section>

      <section>
        <h2>3. Transmisión de la Información</h2>
        <p>Los datos ingresados en el formulario de contacto se envían directamente a nuestro correo electrónico a través de un canal seguro. Al completar el formulario de contacto, el usuario acepta que su información sea utilizada exclusivamente para responder a su consulta.</p>
      </section>

      <section>
        <h2>4. Protección de la Información</h2>
        <p>Nos esforzamos por proteger la información que se envía a través de nuestro sitio web. Utilizamos protocolos seguros de comunicación (HTTPS) y adoptamos medidas de seguridad razonables para garantizar que la información transmitida esté protegida contra accesos no autorizados.</p>
      </section>

      <section>
        <h2>5. Derechos del Usuario</h2>
        <p>Dado que no almacenamos datos de los usuarios en nuestras bases de datos, los usuarios no tienen necesidad de solicitar la eliminación de su información después de enviar el formulario de contacto. Sin embargo, si tienes preguntas sobre nuestra política de privacidad o el manejo de tus datos, no dudes en contactarnos.</p>
      </section>

      <section>
        <h2>6. Cambios en la Política de Privacidad</h2>
        <p>Nos reservamos el derecho de actualizar esta política de privacidad en cualquier momento. Cualquier cambio se publicará en esta página con la fecha de actualización correspondiente. Te recomendamos revisar esta política periódicamente para mantenerte informado sobre cómo protegemos tu privacidad.</p>
      </section>

      <section>
        <h2>7. Contacto</h2>
        <p>Si tienes alguna pregunta sobre esta política de privacidad o sobre cómo manejamos tu información, puedes contactarnos en:</p>
        <ul>
          <li><strong>Correo electrónico:</strong> info@cinfex.es</li>
          <li><strong>Teléfono:</strong> 678968635</li>
        </ul>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
