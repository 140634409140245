import React from 'react';
import './Contacto.css';

const Contacto = () => {
  return (
    <section id="contacto" className="contacto section-offset">
      <div className="contacto-container">
        <h2>Contacto</h2>
        <p className="intro-text">
          Nos encantaría saber de ti. Si tienes alguna consulta, puedes ponerte en contacto con nosotros a través de los siguientes medios:
        </p>
        
        <div className="contact-info">
          <p><strong>Correo Electrónico:</strong> <a href="mailto:info@cinfex.es">info@cinfex.es</a></p>
          <p><strong>Teléfono:</strong> <a href="tel:+34678968635">671263144</a></p>
        </div>

        <p className="additional-info">
          No dudes en escribirnos o llamarnos. Estamos aquí para ayudarte en lo que necesites.
        </p>
      </div>
    </section>
  );
};

export default Contacto;
