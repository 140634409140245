import React from 'react';
import './Inicio.css';
import logo from '../assets/logo_cinfex_sin_fondo.png';
import CookieBanner from './cookiebanner.js'; 

const Inicio = () => (
  <section id="inicio" className="inicio section-offset">
     <CookieBanner />
    <div className="promotion-banner">
      <p>
         <strong className='descuento'>¡Aprovecha nuestra Promoción Especial!</strong>  <br />
        <strong className='descuento'> 50% descuento</strong>
        <br/>
        Disfruta de descuentos exclusivos en nuestros servicios de consultoría informática y desarrollo de software, válidos hasta el <strong>30 de junio de 2025</strong>. <br />
        <em>Nota:</em> Esta promoción aplica únicamente a nuestros servicios profesionales. La compra de material no está incluida en la oferta.
      </p>
    </div>
    <div className="banner">
      <div className="banner-header">
        <h2>CINFEX</h2>
        <img src={logo} alt="Logo CINFEX consultoria informatica extremadura, badajoz, merida, caceres" className="logo" />
      </div>
      <p className="intro-paragraph">
        En <strong>CINFEX Consultoría Informática</strong>, somos una empresa dedicada a ofrecer soluciones tecnológicas avanzadas que impulsan el crecimiento y la transformación digital de las empresas. Nuestra misión es ayudar a las organizaciones a adaptarse al cambiante mundo digital, proporcionando herramientas y estrategias que optimizan sus procesos, mejoran su eficiencia y protegen sus datos.
      </p>
      
      <div className="highlight-box">
        <p>Nos enorgullece ser una empresa profesional y comprometida con el avance tecnológico en Extremadura.</p>
      </div>
      <p className="intro-paragraph">
        Con sede en Extremadura, nos enorgullece ser un referente en el ámbito tecnológico de la región. Nuestra profesionalidad y compromiso nos permiten brindar un servicio de alta calidad, centrado en los valores de innovación, confianza y excelencia.
      </p>
      <p className="subheading">¿Por qué elegir CINFEX?</p>
      <p className="intro-paragraph">
        En CINFEX, contamos con un equipo de expertos altamente capacitados en tecnología y consultoría, que trabajan codo a codo con nuestros clientes para diseñar e implementar soluciones a medida. Desde consultoría en TI hasta el desarrollo de software personalizado y la gestión de la seguridad informática.
      </p>
      <p className="intro-paragraph">
        Nos esforzamos por ser un aliado estratégico en el camino hacia la digitalización, brindando apoyo constante y adaptándonos a las necesidades cambiantes de nuestros clientes. En <strong>CINFEX Consultoría Informática</strong>, no solo ofrecemos servicios, sino soluciones que transforman y potencian el futuro de tu empresa.
      </p>
    </div>
   
  </section>
);

export default Inicio;
