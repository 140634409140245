// src/components/Servicio.js
import React from 'react';
import './Servicio.css';

const Servicio = ({ icono, titulo, descripcion }) => (
  <div className="servicio-card">
    <img src={icono} alt={titulo} className="servicio-icono" />
    <div className="servicio-contenido">
      <h3>{titulo}</h3>
      <p>{descripcion}</p>
    </div>
  </div>
);

export default Servicio;
